import React from "react";
import { AnimatedCard } from "../components/Screens/About/AnimatedCard";
import { GridGallery } from "../components/Screens/About/GridGallery";
import { PageHeader } from "../components/Atomics/PageHeader";
import Layouts from "../components/Layouts";
import Seo from "../components/Seo";
import { TimeLineSection } from "../components/Screens/About/TimeLine";
import { Cta } from "../components/Cta";
import { Leaders } from "../components/Screens/About/Leaders";
import { graphql } from "gatsby";

const AboutPage = ({ data }) => {
	const heroImages = data.page.heroAbout.heroImagesAbout;
	const page = data.page;
	const animatedCards = {
		cardOne: {
			miniHeader: page.animatedCardsAbout.cardsAbout.firstCard.miniHeader,
			header: (
				<div className="2xl:w-7/12 mx-auto  text-center">
					{page.animatedCardsAbout.cardsAbout.firstCard.header}
				</div>
			),
			text: (
				<>
					<p>{page.animatedCardsAbout.cardsAbout.firstCard.text}</p>
				</>
			),
		},
		cardTwo: {
			miniHeader: page.animatedCardsAbout.cardsAbout.secondCard.miniHeader,
			header: (
				<div className="2xl:w-8/12 mx-auto  text-center">
					{page.animatedCardsAbout.cardsAbout.secondCard.header}
				</div>
			),
			text: (
				<>
					<p>{page.animatedCardsAbout.cardsAbout.secondCard.text}</p>
				</>
			),
		},
	};

	return (
		<>
			<Seo title="About" />
			<Layouts lang={"en"}>
				<PageHeader
					miniHeader="ABOUT US"
					header={
						<div className="2xl:w-9/12 mx-auto">
							{page.heroAbout.headerAbout}
						</div>
					}
				/>
				<GridGallery images={heroImages} />
				<AnimatedCard cards={animatedCards} />
				<TimeLineSection
					items={page.timelineAbout.itemsTimeline}
					header="MILESTONES"
				/>
				<Leaders
					leaders={page.leadersAbout.leadersAbout}
					miniHeader="LEADERSHIP"
					header="Meet our Board of Directors"
				/>
				<Cta
					miniHeader="EGYPT's First Nano Financial Service Provider"
					header="Powering Access to Digital Financial Possibilities"
					subHeader="Kashat serves as the entry point to financial inclusion for the un- and underbanked population in Egypt by offering instant, small, short-term, productive loans."
				/>
			</Layouts>
		</>
	);
};

export default AboutPage;
export const pageQu = graphql`
	query EnglishAbout {
		page: wpPage(databaseId: { eq: 349 }) {
			title
			heroAbout {
				miniHeaderAbout
				headerAbout
				heroImagesAbout {
					one {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
					two {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
					three {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
					four {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
				}
			}
			animatedCardsAbout {
				cardsAbout {
					firstCard {
						miniHeader
						header
						text
					}
					secondCard {
						miniHeader
						header
						text
					}
				}
			}
			timelineAbout {
				itemsTimeline {
					date
					text
					logo {
						sourceUrl
					}
				}
			}
			leadersAbout {
				leadersAbout {
					name
					title
					image {
						sourceUrl
					}
					linkedinUrl
				}
			}
		}
	}
`;
